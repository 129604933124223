.card {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  padding: 120px 30px;
  border-radius: 10px;
  display: flex;
  align-items: center;
}

.try-for-free {
  width: 850px;
  max-width: 85%;
}

.try-for-free p {
  text-align: center;
  font-size: 20px;
}

.try-for-free h3 {
  text-align: center;
  padding-bottom: 10px;
  font-size: 40px;
  font-weight: bolder;
}

.try-for-free > p:last-of-type {
  margin-bottom: 30px;
}

p.error {
  margin: 0;
  margin-bottom: -24px;
}

.questions {
  display: flex;
  flex-direction: column;
  width: 800px;
  max-width: 80%;
  margin: 40px auto 80px auto;
  align-items: center;
  min-height: calc(100vh - var(--nav-height) - var(--landing-y-margins));
}
