.showcase-container {
    width: 100%;
    max-width: 1600px;
    /*^ I think that these should be bigger, but idk if this is the best way to do that*/
    margin: auto;
    margin-bottom: 50px;

}

.feature {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 90px 40px;
    margin: 0 auto;
}

.feature-img-container {
    width: 50%;
    min-width: 200px;
    text-align: center;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 4px 4px 20px 0 rgba(0,0,0,.3);
    margin-top: auto;
    margin-bottom: auto;
}

.feature-img {
    width: 100%;
}

.feature-content {
    width: 50%;
    min-width: 280px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-grow:1;
}

.feature-text > *  {
    min-width: 200px;
    margin: 0 20px;
}

.feature-text h3 {
    margin-bottom: 20px;
    font-weight: bolder;
}

.wrap {
    flex-wrap: wrap;
    justify-content: center;
    /* background-color: #e5f0ff; */
}

.wrap-reverse {
    flex-wrap: wrap-reverse;
    justify-content: center;
}

.watch-demo {
    color: #D75249;
    cursor: pointer;
}

.watch-demo:hover {
    text-decoration: underline;
}

/* mobile */
@media only screen and (max-width: 600px) {
    .feature {
        padding: 60px 20px 20px 20px;
    }

    .feature-img-container {
        padding: 0;
        width: 100%;
    }

    .feature-content {
        padding: 30px 0 0 0 !important;
        width: 100%;
    }


    .wrap-reverse .feature-content {
        flex-direction: row-reverse;
    }

    .feature-cta-container {
        justify-content: center !important;
        margin:0;
    }
}

.feature.wrap-reverse .feature-content {
    padding-right: 20px;
}

.feature.wrap .feature-content {
    padding-left: 20px;
}

.feature-cta-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 20px;
    justify-content: flex-start;
}

.feature-cta-container .main-cta {
    margin: 10px 0;
    font-size:20px;
    min-width: 272px;
    padding: 15px 30px !important;
    text-align: center;
    line-height: 38px;
    border: 2px;
}

@media (max-width: 1324px) {
    .feature-cta-container .main-cta {
        margin: 10px 0;
        font-size:16px;
        min-width: 150px;
        padding: 10px 20px !important;
        text-align: center;
        height: 50px;
        line-height: 30px !important;
    }
}

.feature-cta-container .main-cta:first-child {
    margin-right: 10px;
    line-height: 42px;
}

.no-shadow {
    box-shadow: none !important;
}