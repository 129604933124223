.btn-context-gradient {
	background-image: linear-gradient(274.93deg, #52C6D5 -4.98%, #2C469C 93.65%);
	border-radius: 50px;
	padding: 10px 50px;
	border: none;
	color: white;
	font-size: large;
	margin: 5px 20px;
}

.form-input {
	border: none;
	border-radius: 50px;
	background-color: #f2f2f2;
	border: 1px solid #c9c9c9;
	padding: 0 30px;
	height: 48px;
	margin: 0 20px;
	max-width: 250px;
}

.form-group {
	display: flex;
	justify-content: center;
	flex-direction: row;
	flex-wrap: wrap;
	width:100%;
}

.form-group * {
	margin-bottom: 10px;
}

.landing {
	display: flex;
	flex-direction: column;
	width: 800px;
	max-width: 80%;
	margin: 40px auto 80px auto;
	align-items: center;
}

.landing h1 {
	max-width: 700px;
	font-size: 50px;
}

@media (max-width: 600px) {
	.landing h1 {
		font-size: 30px;
	}
}

.landing p:first-of-type {
	max-width: 540px;
}

.landing-image {
	width: 1100px;
	max-width: 90vw;
	height: auto;
	margin-top: 70px;
	box-shadow: 0 4px 20px 0 rgba(0,0,0,.3);
	border-radius: 3%/5.6%;
}

.main-cta {
    background: var(--context-gradient);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 93px;
    color: white !important;
    text-decoration: none;
    padding: 15px 60px !important;
	font-size: 24px;
	min-width: 310px;
}

.ghost {
    border-radius: 93px;
    color: #4289bb !important;
	background: none;
	box-shadow: none;
	border: 2px solid #4289bb !important;
}

