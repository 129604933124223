.integrationCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 4px 39px 0 rgba(0,0,0,.15);
    padding: 20px;
    margin: 30px;
    min-width: 200px;
    width: min(90%, 400px);
    border-radius: 20px;
    position: relative;
}

.integrationCard img {
    width: 150px;
    margin: 40px auto;
}

.comingSoon {
    position:absolute;
    top:-19px;
    right:-22px;
    padding: 10px;
    background-color: rgb(253, 194, 0);
    color: black;
    border-radius: 10px;
    font-style: italic;
}

.integrationsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width:100%;
    max-width: 100%;
}

.integrationsHeader {
    font-weight: bold;
    font-size: 70px;
}

@media (max-width: 600px) {
    .integrationsHeader {
        font-size: 40px;
    }
}