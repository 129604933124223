.container {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
	width:100%;
	margin-top: 60px;
	margin-bottom: 60px;
}

.landing {
	display: flex;
	flex-direction: column;
	min-width: 35%;
	max-width: min(80%, 500px);
	padding-bottom: 40px;
	align-items: center;
	flex-grow: 1;
	margin-right: 20px;
	margin-left: 20px;
}

.landing h1 {
	font-size: 30px;
}

@media (max-width: 600px) {
	.landing h1 {
		font-size: 30px;
	}
}

.landing p:first-of-type {
	max-width: 540px;
	font-size: 16px;
}

.landingImgContainer {
	width: 700px;
	max-width: 90vw;
	margin-right: 20px;
	margin-left: 20px;
}

.landingImg {
	width: 700px;
	max-width: 90vw;
	height: auto;
	box-shadow: 0 4px 20px 0 rgba(0,0,0,.3);
	border-radius: 3%/5.6%;
}