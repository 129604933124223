.problem {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-image: linear-gradient(94.16deg, #2C469C -6.96%, #52C6D5 99.14%);
    padding: 50px 20px;
    color: white;
    text-align: center;
    width: 100%;
}

.problem p:first-of-type {
    font-size: 18px;
    width: 1200px;
    max-width: 100%;
}
 
.problem h2 {
    font-weight: 400;
    font-size: 125px;
}

@media only screen and (max-width: 600px)  {
    .problem h2 {
        font-size: 57px;
    }
}

.problem a {
    padding-top: 0px;
    font-style: italic;
    text-decoration: underline;
    color: rgb(233, 233, 233);
}

.problem a:hover {
    padding-top: 0px;
    font-style: italic;
    color: white;
    text-decoration: underline;
}
.problem a:active {
    padding-top: 0px;
    font-style: italic;
    color: white;
}

.quoteCard {
    box-shadow: 0 4px 20px 0 rgba(0,0,0,0.6);
    padding: 50px 30px;
    border-radius: 10px;
    width: 40%;
    min-width: min(600px, 100%);
    margin: 15px;
    background-color: var(--background-1);
    color: var(--text-primary);
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
}

.quoteCard .profileImg {
    max-width: 200px;
    border-radius: 50%;
    box-shadow: 4px 4px 10px 0 rgba(0,0,0,0.6);
    margin: 0 15px 30px 15px;
}

.quoteContents {
    width: 50%;
    flex-grow: 1;
    margin:0 15px;
}

.quoteContents p {
    width: 100%;
    text-align: left;
}

.quote-container {
    display: flex;
    justify-content: center;
    margin: 20px 0;
    flex-wrap: wrap;
    width: 100%;
}

.Blue {
    color: #D76069;
}

.lightBlue {
    color: #55c6d6;
}

.quoteLogo {
    max-width: 100px;
}