.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -55px;
}

.fact {
    width:100%;
    min-height: 100px;
    color: white;
    font-size: 20px;
    padding-right: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.factContainer {
    background-image: var(--context-gradient);
    display: flex;
    justify-content: center;
    width: 100%;
    padding-bottom: 40px;
}

.factList {
    max-width: max(70%, 600px);
}