.container {
    margin: 50px auto;
    max-width: 80%;
    width: 700px;
}

.container p {
    text-align: center;
    padding: 0 20px;
}

.container img {
    width: 100%;
}