.container {
    padding: 40px 15px;
}

.container h3, .container p {
    text-align: center;
    max-width: 570px;
    margin: 0 auto;
    padding-bottom: 10px;
}

.solutions {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 900px;
    justify-content: center;
    margin: 30px auto;
}

.solutions a {
    height: 200px;
    width: 200px;
    box-shadow: 4px 4px 20px 0 rgba(0,0,0,.2);
    margin: 15px;
    padding: 10px;
    border-radius: 15px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    cursor: pointer;
    color: initial;
    text-decoration: none;
}

.solutions a:hover {
    background-color: var(--context-light-blue);
    color: white;
}