.wordLogo {
	max-height: 35px;
	width: auto;
	cursor: pointer;
}

@media only screen and (max-width: 600px) {
	.collapsible-nav {
		display: none;
	}
}

.container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: 100vh;
}

.banner {
	width: 100%;
	min-height: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-image: var(--context-gradient);
	color: white;
	padding: 5px 20px;
	text-align: center;
}