.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-image: var(--context-gradient);
    padding: 50px 40px 2px 40px;
    color: white;
    text-align: center;
    width: 100%;
}

.footer h3 {
    margin-bottom: 20px;
}

.footer-cta {
    background: none;
    margin: 20px auto 30px auto;
    padding: 10px 40px !important;
    min-width: 150px;
    font-size: 20px;
    background-color: white;
    color: var(--text-primary) !important;
}

.footer-add-to-slack {
    margin: 20px 0 50px 0;
}

.footer p.error {
    color: white;
}

.footer-links {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.footer-links * {
    font-size: 12px;
    color: #c7c7c7 !important;
    text-decoration: none;
    margin: 0 5px;    
}