.App {
  	text-align: center;
}

body {
	font-family: poppins;
	--context-gradient: linear-gradient(270deg, #55C6D6, #2C469C);
	--background-1: white;
	--text-primary: #333;
	--text-secondary: white;
	--context-light-blue: #55c6d6;
	--context-dark-blue: #2c469c;
}

.wrapper {
  	min-height: 100vh;
  	position: relative;
  	padding-bottom: 275px;
}



.nav-link {
	text-align: right;
}

@media (min-width: 768px) {
	.nav-cta {
		background: var(--context-gradient);
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		border-radius: 93px;
		color: white !important;
		text-decoration: none;
		padding: 10px 30px !important;
		margin-left: 15px;
	}

	.nav-cta:hover {
		color: rgba(255,255,255,.7) !important;
	}
}

.flex-row {
	display: flex;
	flex-direction: row;
}

.flex-col {
	display: flex;
	flex-direction: column;
}

.justify-center {
	justify-content: center;
}

.justify-around {
	justify-content: space-around;
}

.align-center {
	align-items: center;
}

.error {
	color: red;
}

.bold {
	font-weight: bolder;
}

.italic {
	font-style: italic;
}

.huge-text {
	font-size: 5rem;
	margin: 0 20px;
}